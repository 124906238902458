var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "500" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: {
                            color: "primary",
                            elevation: "1",
                            fab: "",
                            small: "",
                          },
                          on: { click: _vm.addGroupButton },
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_c("v-icon", [_vm._v("mdi-plus")])],
                    1
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline primary" }, [
                _vm._v("Add Group"),
              ]),
              _c(
                "v-card-text",
                { staticClass: "pa-8" },
                [
                  _c(
                    "v-form",
                    {
                      ref: "addGroupForm",
                      model: {
                        value: _vm.valid,
                        callback: function ($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid",
                      },
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          rules: [
                            _vm.rules.required,
                            _vm.rules.no_spaces,
                            _vm.rules.minMaxLength(1, 32),
                          ],
                          type: "text",
                          label: "Group Name",
                        },
                        model: {
                          value: _vm.form.group.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form.group, "name", $$v)
                          },
                          expression: "form.group.name",
                        },
                      }),
                      _c("br"),
                      _c(
                        "v-row",
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-12 text-center align-center justify-center subtitle-2",
                            },
                            [_vm._v(" Group Config: ")]
                          ),
                          _c("v-autocomplete", {
                            staticClass: "col-12",
                            attrs: {
                              items: _vm.innerConfigsList,
                              "item-text": "id",
                              label: "Select Group Config",
                              "return-object": "",
                              "search-input": _vm.configQuery,
                              loading: _vm.configLoading,
                              disabled: _vm.configLoading,
                              filter: _vm.configFilter,
                            },
                            on: {
                              "update:searchInput": function ($event) {
                                _vm.configQuery = $event
                              },
                              "update:search-input": function ($event) {
                                _vm.configQuery = $event
                              },
                              input: _vm.fillConfigForm,
                              change: _vm.configChange,
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "selection",
                                fn: function ({ item }) {
                                  return [
                                    item.id == -1
                                      ? _c(
                                          "span",
                                          { staticClass: "mx-3 text-body-2" },
                                          [_vm._v(" New Config ")]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            staticClass: "mx-3 text-body-2",
                                            attrs: { close: "" },
                                            on: {
                                              "click:close": function ($event) {
                                                return _vm.configChange(null)
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "mr-3" },
                                              [_vm._v("ID: " + _vm._s(item.id))]
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "mr-3" },
                                              [
                                                _vm._v(
                                                  "Currency: " +
                                                    _vm._s(item.currency)
                                                ),
                                              ]
                                            ),
                                            _c("span", [
                                              _vm._v(
                                                "Contribution: " +
                                                  _vm._s(item.contrib)
                                              ),
                                            ]),
                                          ]
                                        ),
                                  ]
                                },
                              },
                              {
                                key: "item",
                                fn: function ({ item }) {
                                  return [
                                    item.id == -1
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "d-flex text-body-2",
                                            staticStyle: { width: "100%" },
                                          },
                                          [_vm._v(" New Config ")]
                                        )
                                      : _c(
                                          "div",
                                          {
                                            staticClass: "d-flex text-body-2",
                                            staticStyle: { width: "100%" },
                                          },
                                          [
                                            _c("span", [
                                              _c(
                                                "span",
                                                { staticClass: "mr-3" },
                                                [
                                                  _vm._v(" ID: "),
                                                  _c(
                                                    "span",
                                                    {
                                                      class: {
                                                        "primary--text":
                                                          _vm.configQuery &&
                                                          _vm.configQuery
                                                            .split(" ")
                                                            .some((el) =>
                                                              item.id
                                                                .toString()
                                                                .includes(el)
                                                            ),
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(item.id) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "mr-3" },
                                                [
                                                  _vm._v(" Currency: "),
                                                  _c(
                                                    "span",
                                                    {
                                                      class: {
                                                        "primary--text":
                                                          _vm.configQuery &&
                                                          _vm.configQuery
                                                            .split(" ")
                                                            .some((el) =>
                                                              item.currency
                                                                .toUpperCase()
                                                                .includes(
                                                                  el.toUpperCase()
                                                                )
                                                            ),
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.currency
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c("span", [
                                                _vm._v(" Contribution: "),
                                                _c(
                                                  "span",
                                                  {
                                                    class: {
                                                      "primary--text":
                                                        _vm.configQuery &&
                                                        (_vm.configQuery
                                                          .split(" ")
                                                          .some((el) =>
                                                            item.contrib
                                                              .toString()
                                                              .includes(el)
                                                          ) ||
                                                          _vm.configQuery
                                                            .split(" ")
                                                            .some(
                                                              (el) =>
                                                                (el == "%" &&
                                                                  item.contrib_type_id ==
                                                                    1) ||
                                                                (el == "!%" &&
                                                                  item.contrib_type_id ==
                                                                    2) ||
                                                                (el.endsWith(
                                                                  "%"
                                                                ) &&
                                                                  el ==
                                                                    item.contrib.toString() +
                                                                      "%")
                                                            )),
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.contrib) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    class: {
                                                      "primary--text":
                                                        _vm.configQuery &&
                                                        _vm.configQuery
                                                          .split(" ")
                                                          .some(
                                                            (el) =>
                                                              (el == "%" &&
                                                                item.contrib_type_id ==
                                                                  1) ||
                                                              (el.endsWith(
                                                                "%"
                                                              ) &&
                                                                el ==
                                                                  item.contrib.toString() +
                                                                    "%")
                                                          ),
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.contrib_type_id ==
                                                            1
                                                            ? "%"
                                                            : ""
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                            ]),
                                          ]
                                        ),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.form.group_config,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "group_config", $$v)
                              },
                              expression: "form.group_config",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("v-text-field", {
                        attrs: {
                          suffix: "%",
                          rules: [
                            _vm.rules.required,
                            _vm.rules.minMaxValue(0, 100),
                          ],
                          label: "Contribution",
                          disabled: _vm.form.group_config.id > -1,
                        },
                        model: {
                          value: _vm.newConfigForm.contrib,
                          callback: function ($$v) {
                            _vm.$set(_vm.newConfigForm, "contrib", _vm._n($$v))
                          },
                          expression: "newConfigForm.contrib",
                        },
                      }),
                      _c("v-select", {
                        attrs: {
                          items: _vm.contribTypeDropdown,
                          "item-value": "id",
                          "item-text": "contribType",
                          label: "Select Contribution Type",
                          rules: [_vm.rules.required],
                          disabled: _vm.form.group_config.id > -1,
                        },
                        model: {
                          value: _vm.newConfigForm.contrib_type_id,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.newConfigForm,
                              "contrib_type_id",
                              _vm._n($$v)
                            )
                          },
                          expression: "newConfigForm.contrib_type_id",
                        },
                      }),
                      _c("v-select", {
                        staticClass: "currency-code",
                        attrs: {
                          items: _vm.currencyCodes,
                          rules: [_vm.rules.required, _vm.rules.currency],
                          type: "text",
                          label: "Currency",
                          disabled: _vm.form.group_config.id > -1,
                        },
                        model: {
                          value: _vm.newConfigForm.currency,
                          callback: function ($$v) {
                            _vm.$set(_vm.newConfigForm, "currency", $$v)
                          },
                          expression: "newConfigForm.currency",
                        },
                      }),
                      _c("br"),
                      _c(
                        "v-row",
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-12 text-center align-center justify-center subtitle-2",
                            },
                            [_vm._v(" Group Theme: ")]
                          ),
                          _c("v-text-field", {
                            staticClass: "cols-6 mt-3 mx-3",
                            attrs: {
                              rules: [
                                _vm.rules.required,
                                _vm.rules.no_spaces,
                                _vm.rules.minMaxLength(3, 42),
                              ],
                              label: "Group Theme Name",
                              disabled: _vm.form.theme.id > -1,
                              "error-messages": _vm.asyncErrors,
                            },
                            model: {
                              value: _vm.form.theme.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form.theme, "name", $$v)
                              },
                              expression: "form.theme.name",
                            },
                          }),
                          _c("v-autocomplete", {
                            key: _vm.form.theme.name,
                            staticClass: "col-6 mt-3",
                            attrs: {
                              items: _vm.innerThemesList,
                              "item-text": "name",
                              "item-value": "id",
                              label: "Select Group Theme",
                              "return-object": "",
                              loading: _vm.configLoading,
                              disabled: _vm.configLoading,
                              filter: _vm.configFilter,
                            },
                            on: { change: _vm.configChange },
                            scopedSlots: _vm._u([
                              {
                                key: "selection",
                                fn: function ({ item }) {
                                  return [
                                    item.id == -1
                                      ? _c("span", [_vm._v("New Theme")])
                                      : _c("span", [_vm._v(_vm._s(item.name))]),
                                  ]
                                },
                              },
                              {
                                key: "item",
                                fn: function ({ item }) {
                                  return [
                                    item.id == -1
                                      ? _c("span", [_vm._v("New Theme")])
                                      : _c("span", [_vm._v(_vm._s(item.name))]),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.form.theme,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "theme", $$v)
                              },
                              expression: "form.theme",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("br"),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          _vm.dialog = false
                        },
                      },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        text: "",
                        disabled: !_vm.valid,
                      },
                      on: { click: _vm.submit },
                    },
                    [_vm._v(" Submit ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }