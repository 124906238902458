<template>
	<div class="text-center">
		<v-dialog
			v-model="dialog"
			width="500">
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					:color="'primary'"
					elevation="1"
					fab
					small
					v-bind="attrs"
					v-on="on"
					@click="addGroupButton">
					<v-icon>mdi-plus</v-icon>
				</v-btn>
			</template>

			<v-card>
				<v-card-title class="headline primary">Add Group</v-card-title>
				<v-card-text class="pa-8">
					<v-form
						ref="addGroupForm"
						v-model="valid">
						<v-text-field
							v-model="form.group.name"
							:rules="[
								rules.required,
								rules.no_spaces,
								rules.minMaxLength(1, 32),
							]"
							type="text"
							label="Group Name" />
						<br />
						<v-row>
							<div
								class="col-12 text-center align-center justify-center subtitle-2">
								Group Config:
							</div>
							<v-autocomplete
								class="col-12"
								:items="innerConfigsList"
								v-model="form.group_config"
								item-text="id"
								label="Select Group Config"
								return-object
								:search-input.sync="configQuery"
								:loading="configLoading"
								:disabled="configLoading"
								@input="fillConfigForm"
								@change="configChange"
								:filter="configFilter">
								<template #selection="{ item }">
									<span
										class="mx-3 text-body-2"
										v-if="item.id == -1">
										New Config
									</span>
									<span
										class="mx-3 text-body-2"
										close
										@click:close="configChange(null)"
										v-else>
										<span class="mr-3">ID: {{ item.id }}</span>
										<span class="mr-3">Currency: {{ item.currency }}</span>
										<span>Contribution: {{ item.contrib }}</span>
									</span>
								</template>
								<template #item="{ item }">
									<div
										v-if="item.id == -1"
										style="width: 100%"
										class="d-flex text-body-2">
										New Config
									</div>
									<div
										v-else
										style="width: 100%"
										class="d-flex text-body-2">
										<span>
											<span class="mr-3">
												ID:
												<span
													:class="{
														'primary--text':
															configQuery &&
															configQuery
																.split(' ')
																.some((el) => item.id.toString().includes(el)),
													}">
													{{ item.id }}
												</span>
											</span>
											<span class="mr-3">
												Currency:
												<span
													:class="{
														'primary--text':
															configQuery &&
															configQuery
																.split(' ')
																.some((el) =>
																	item.currency
																		.toUpperCase()
																		.includes(el.toUpperCase())
																),
													}">
													{{ item.currency }}
												</span>
											</span>
											<span>
												Contribution:
												<span
													:class="{
														'primary--text':
															configQuery &&
															(configQuery
																.split(' ')
																.some((el) =>
																	item.contrib.toString().includes(el)
																) ||
																configQuery
																	.split(' ')
																	.some(
																		(el) =>
																			(el == '%' &&
																				item.contrib_type_id == 1) ||
																			(el == '!%' &&
																				item.contrib_type_id == 2) ||
																			(el.endsWith('%') &&
																				el == item.contrib.toString() + '%')
																	)),
													}">
													{{ item.contrib }}
												</span>
												<span
													:class="{
														'primary--text':
															configQuery &&
															configQuery
																.split(' ')
																.some(
																	(el) =>
																		(el == '%' && item.contrib_type_id == 1) ||
																		(el.endsWith('%') &&
																			el == item.contrib.toString() + '%')
																),
													}">
													{{ item.contrib_type_id == 1 ? "%" : "" }}
												</span>
											</span>
										</span>
									</div>
								</template>
							</v-autocomplete>
						</v-row>
						<v-text-field
							v-model.number="newConfigForm.contrib"
							suffix="%"
							:rules="[rules.required, rules.minMaxValue(0, 100)]"
							label="Contribution"
							:disabled="form.group_config.id > -1" />
						<v-select
							:items="contribTypeDropdown"
							v-model.number="newConfigForm.contrib_type_id"
							item-value="id"
							item-text="contribType"
							label="Select Contribution Type"
							:rules="[rules.required]"
							:disabled="form.group_config.id > -1" />
						<v-select
							v-model="newConfigForm.currency"
							:items="currencyCodes"
							:rules="[rules.required, rules.currency]"
							type="text"
							class="currency-code"
							label="Currency"
							:disabled="form.group_config.id > -1"></v-select>
						<br />
						<v-row>
							<div
								class="col-12 text-center align-center justify-center subtitle-2">
								Group Theme:
							</div>
							<v-text-field
								class="cols-6 mt-3 mx-3"
								v-model="form.theme.name"
								:rules="[
									rules.required,
									rules.no_spaces,
									rules.minMaxLength(3, 42),
								]"
								label="Group Theme Name"
								:disabled="form.theme.id > -1"
								:error-messages="asyncErrors" />
							<v-autocomplete
								class="col-6 mt-3"
								:items="innerThemesList"
								:key="form.theme.name"
								v-model="form.theme"
								item-text="name"
								item-value="id"
								label="Select Group Theme"
								return-object
								:loading="configLoading"
								:disabled="configLoading"
								@change="configChange"
								:filter="configFilter">
								<template #selection="{ item }">
									<span v-if="item.id == -1">New Theme</span>
									<span v-else>{{ item.name }}</span>
								</template>
								<template #item="{ item }">
									<span v-if="item.id == -1">New Theme</span>
									<span v-else>{{ item.name }}</span>
								</template>
							</v-autocomplete>
						</v-row>
					</v-form>
				</v-card-text>

				<br />
				<v-divider />
				<v-card-actions>
					<v-btn
						text
						@click="dialog = false">
						Cancel
					</v-btn>
					<v-spacer />
					<v-btn
						color="primary"
						text
						@click="submit"
						:disabled="!valid">
						Submit
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Vue from "vue";
import { rules as defaultRules } from "../../../plugins/rules.ts";
import { currencyCodes } from "../../../constants/currencyCodes";
import { CONTRIB_TYPES } from "../../../constants/contribTypes";

export default {
	props: {
		color: {
			type: String,
			default: "primary",
		},
		configLoading: {
			type: Boolean,
			default: true,
		},
		themesLoading: {
			type: Boolean,
			default: true,
		},
		configs: {
			type: Array,
			default: function () {
				return [];
			},
		},
		themes: {
			type: Array,
			default: function () {
				return [];
			},
		},
	},

	data() {
		return {
			configQuery: null,
			unsetGroupConfig: { id: -1 },
			form: {
				group: {},
				group_config: {},
				theme: {},
			},
			configFilter: (item, query) => {
				if (!this.configQuery) return true;
				if (item.id === -1) return true;
				return query.split(" ").reduce((prev, next) => {
					if (next == "!%") {
						return prev && item.contrib_type_id == 2;
					}

					if (next == "%") {
						return prev && item.contrib_type_id == 1;
					}

					if (next.toUpperCase().includes("ID:")) {
						return (
							prev && ("ID:" + item.id.toString()).includes(next.toUpperCase())
						);
					}

					let res = false;
					if (next.endsWith("%")) {
						res =
							res ||
							(item.contrib_type_id == 1 && item.contrib == next.split("%")[0]);
					}
					res = res || item.currency.toUpperCase().includes(next.toUpperCase());
					res = res || item.id.toString().includes(next);
					res = res || item.contrib.toString().includes(next);

					return prev && res;
				}, true);
			},
			//TO ALL THE THINGS THAT COULD HAVE BEEN
			// configFilter: (item, query) => {
			//   if (item.id === -1) return true;
			//   return query.split(",").reduce((result, sub) => {
			//     // if (sub === "" || sub === " ") return false;
			//     return (
			//       sub.split(" ").reduce((prev, next) => {
			//         if (next === "") return prev;
			//         if (next == "!%") {
			//           return prev && item.contrib_type_id == 2;
			//         }

			//         if (next == "%") {
			//           return prev && item.contrib_type_id == 1;
			//         }
			//         let res = false;
			//         res = res || item.currency.toUpperCase().includes(next.toUpperCase());
			//         res = res || item.id.toString().includes(next);
			//         res = res || item.contrib.toString().includes(next);

			//         return prev && res;
			//       }, true) || result
			//     );
			//   }, false);
			// },
			newConfigForm: {},
			groupName: "",
			contrib: undefined,
			contribTypeId: 1,
			contribTypeDropdown: [
				{ id: 1, contribType: CONTRIB_TYPES.PERCENT },
				{ id: 2, contribType: CONTRIB_TYPES.BET },
			],
			currency: undefined,
			currencyCodes,
			valid: true,
			rules: {
				...defaultRules,
				currency: (v) =>
					this.currencyCodes.includes(v) || "Must be a valid currency code",
			},
			dialog: false,
			selectedConfig: { id: "New Group Config" },
			selectedTheme: { id: -1, name: "New Group Theme" },
			themeName: "",
			groupsUsingConfig: [],
			blockConfigForm: false,
			blockThemeForm: false,
			invalid: true,
			asyncErrors: [],
		};
	},

	computed: {
		...mapGetters([
			"createdJackpotGroup",
			"getListGroupsWithConfig",
			"getCreatedGroupConfig",
			"getDefaultScheme",
			"getFilteredLevelList",
			"getCreatedTheme",
		]),

		callParams() {
			return {
				// groupName: this.groupName,
				contrib: Number(this.contrib),
				contrib_type_id: Number(this.contribTypeId),
				currency: this.currency,
			};
		},
		innerConfigsList() {
			return [{ id: -1 }, ...this.configs];
		},
		innerThemesList() {
			return [{ id: -1, name: "new_group_theme" }, ...this.themes];
		},
	},
	methods: {
		async configChange(e) {
			if (!e) {
				Vue.set(this.form, "group_config", {});
				await this.$nextTick();
				Vue.set(this.form, "group_config", this.innerConfigsList[0]);
				await this.$nextTick();
				this.fillConfigForm(this.form.group_config);
			}
		},
		...mapActions("jackpotGroupConfig2", {
			createConfig: "create",
		}),
		...mapActions("jackpotTheme2", {
			createTheme: "create",
			themeExists: "exists",
		}),
		...mapActions("jackpotGroup2", {
			createGroup: "create",
		}),
		fillThemeForm(e) {
			if (e.id === -1) {
				this.form.theme = {};
			}
		},
		fillConfigForm(e) {
			if (e.id === -1) {
				this.newConfigForm = {};
				return;
			}
			this.newConfigForm = {
				contrib: e.contrib,
				contrib_type_id: e.contrib_type_id,
				currency: e.currency,
			};
		},

		async submit() {
			let config = this.form.group_config;
			let theme = this.form.theme;
			if (this.form.group_config.id === -1) {
				config = await this.createConfig(this.newConfigForm);
			}
			if (this.form.theme.id === -1) {
				theme = await this.createTheme(this.form.theme);
			}
			const res = {
				...this.form.group,
				// contrib: config.contrib,
				group_config_id: config.id,
				theme: theme.name,
			};
			await this.createGroup(res);
			this.dialog = false;
			this.$emit("reload");
		},

		validateForm() {
			if (this.$refs.addGroupForm.validate()) {
				this.invalid = false;
			}
		},

		async addGroupButton() {
			this.$emit("addingGroup");
		},

		async getListOfGroupsWithConfig(configId) {
			await this.loadGroupsListWithConfig({ groupConfigId: configId });
			this.groupsUsingConfig = this.list.data;
			this.textWarningColor = this.groupsUsingConfig.length > 1 ? "error" : "";
		},
	},

	watch: {
		"form.theme": {
			deep: true,
			handler: async function (newVal) {
				if (newVal.id > -1) {
					this.asyncErrors = [];
					return;
				}

				if (newVal.name === "") {
					return;
				}

				this.asyncErrors = ["Validating..."];
				const res = await this.themeExists(newVal.name);
				this.asyncErrors = res ? ["This theme already exists."] : [];
			},
		},
	},
	created() {
		this.form.group_config = this.unsetGroupConfig;
		Vue.set(this.form, "theme", this.innerThemesList[0]);
	},
};
</script>

<style scoped>
.pointer {
	cursor: pointer;
}
</style>
