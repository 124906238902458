<template>
	<v-container
		id="Groups"
		fluid
		tag="section">
		<v-row dense>
			<v-col>
				<v-card class="v-card--material pa-3">
					<card-heading
						:color="sectionColor"
						:title="'Bonus Groups'">
						<template #append>
							<AddGroup
								v-if="
									_usrFlagsSome({
										key: 'bonus.groups.detail',
										val: permissions.CREATE,
									})
								"
								:color="sectionColor + ' lighten-1'"
								:configs="configListLocal"
								:themes="themesListLocal"
								:configLoading="configLoading"
								:themesLoading="themesLoading"
								v-on:addingGroup="loadAddGroupData"
								@reload="_loadTable" />
						</template>
					</card-heading>
					<v-data-table
						:headers="filteredHeaders"
						:items="list"
						:options.sync="options"
						:server-items-length="total_records"
						:item-class="itemClass"
						:loading="loading"
						:footer-props="footerProps"
						class="elevation-1"
						@click:row="handleClick">
						<template #top="{ pagination }">
							<div class="d-flex align-center justify-space-between">
								<v-autocomplete
									class="mx-3 col-3"
									small-chips
									clearable
									hide-details
									:label="`Casino`"
									:filter="filterFn"
									:items="allowedCasinos"
									v-model="_casinoFilter"
									item-value="casino_id"
									item-text="website">
									<template #item="{ item }">
										<span>{{ item.website }}</span>
										<v-spacer></v-spacer>
										<v-chip small>{{ item.casino_id }}</v-chip>
									</template>
								</v-autocomplete>
								<v-data-footer
									class="anton"
									:options.sync="options"
									v-bind="footerProps"
									:pagination="pagination"></v-data-footer>
							</div>
						</template>
						<template #[`item.name`]="{ item }">
							{{ item.name }}
							<v-chip
								class="ml-3"
								v-if="
									item.deleted &&
									_usrFlagsSome({
										key: 'bonus.group_themes.list',
										val: permissions.DELETE,
									})
								"
								small
								color="error">
								Deleted
							</v-chip>
						</template>
						<template v-slot:[`item.group_config_id`]="{ item }">
							{{ item.group_config_id }}
						</template>
						<template
							v-if="
								_usrFlagsSome({
									key: ['bonus.group_themes.detail'],
									val: permissions.READ,
								})
							"
							v-slot:[`item.theme_id`]="{ item }">
							<v-avatar
								size="26px"
								class="hoverTheme body-2 font-weight-bold primary--text"
								@click.prevent="toTheme(item)">
								{{ item.theme_id }}
							</v-avatar>
						</template>
						<template v-slot:[`item.casinos`]="{ item }">
							<v-chip
								small
								:class="`px-2 mr-1 secondary ${
									$vuetify.theme.dark ? '' : 'darken-2'
								}`"
								v-for="casino in item.casinos.slice(0, maxChips)"
								:key="`casinochip-${casino}`">
								{{ casino }}
							</v-chip>
							<v-tooltip
								max-width="300px"
								color="primary darken-2"
								bottom>
								<template #activator="{ on, attrs }">
									<span
										v-on="on"
										v-bind="attrs"
										class="primary--text"
										v-if="item.casinos.length > maxChips">
										+{{ item.casinos.length - maxChips }} other{{
											item.casinos.length - maxChips > 1 ? "s" : ""
										}}
									</span>
								</template>
								<span class="d-flex flex-wrap">
									{{ item.casinos.slice(maxChips).join(", ") }}
								</span>
							</v-tooltip>
						</template>
						<template v-slot:[`item.deleted`]="{ item }">
							{{ item.deleted | Date }}
						</template>
					</v-data-table>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import cardHeading from "../../components/shared/CardHeading.vue";
import AddGroup from "../../components/jackpots/group/AddGroup.vue";
import permissions from "../../mixins/permissions";
import table from "../../mixins/table";

// TODO: [L2-105] ADD EDIT ICONS TO THE TABLE

export default {
	components: {
		cardHeading,
		AddGroup,
	},
	mixins: [permissions, table],
	data() {
		return {
			casinoFilter: null,

			addDialog: false,
			maxChips: 5,
			configListLocal: [],
			themesListLocal: [],
			configLoading: true,
			themesLoading: true,
			options: {},
			loading: false,
			total_records: 0,
			sectionColor: "secondary",
			search: "",
			headers: [
				{ text: "id", value: "id", align: "center" },
				{ text: "Name", value: "name", align: "left" },
				{ text: "Casinos", value: "casinos", align: "center", sortable: false },
				{ text: "Config ID", value: "group_config_id", align: "center" },
				{ text: "Theme ID", value: "theme_id", align: "center" },
				{
					text: "Deleted",
					value: "deleted",
					align: "right",
					hide: () =>
						!this._usrFlagsSome({
							key: "bonus.groups.list",
							val: this.permissions.DELETE,
						}),
				},
			],
		};
	},

	computed: {
		_casinoFilter: {
			get() {
				return this.casinoFilter;
			},
			set(content) {
				this.casinoFilter = content;
				if (this.options.page !== 1) {
					this.options.page = 1;
				} else {
					this._loadTable();
				}
			},
		},
		rowIsClickable() {
			return this._usrFlagsSome({
				key: "bonus.groups.detail",
				val: this.permissions.READ,
			});
		},
		...mapGetters("jackpotGroup2", {
			list: "list",
			pagination: "pagination",
		}),
		...mapGetters("jackpotGroupConfig2", {
			getListOfGroupConfigs: "list",
		}),
		...mapGetters("jackpotTheme2", {
			getThemesList: "list",
		}),
		...mapGetters({
			allowedCasinos: "allowedCasinos",
		}),
	},

	methods: {
		filterFn(item, query) {
			return (
				item.website.includes(query) ||
				item.casino_id.toString().includes(query)
			);
		},
		toTheme(item) {
			this.$router.push({
				name: "theme-detail",
				params: {
					theme_name: item.theme_name,
					breadcrumbs: { title: item.name },
				},
			});
		},
		...mapActions("jackpotGroup2", {
			loadGroups: "loadList",
			deleteItem: "delete",
			restoreItem: "unDelete",
			createItem: "create",
			updateItem: "update",
		}),
		...mapActions("jackpotGroupConfig2", {
			loadGroupConfigs: "loadList",
		}),
		...mapActions("jackpotTheme2", {
			loadThemesList: "loadList",
		}),
		...mapMutations("jackpotGroup2", {
			setPagination: "pagination",
		}),

		handleClick(value) {
			if (!this.rowIsClickable) return;

			this.$router.push({
				name: "jackpot-group-detail",
				params: {
					jackpot_group_id: value.id ? value.id : value.group_id,
					breadcrumbs: {
						title: value.id,
					},
				},
			});
		},

		async loadAddGroupData() {
			/** THIS NEED FIXING , CAN'T LOAD ALL CONFIGS, AND FIXED NUMBER ISN'T GOOD SOLUTION */
			this.configLoading = true;
			this.themesLoading = true;
			const configPagination = await this.loadGroupConfigs({
				query: { per_page: 1, page: 1 },
			});
			const themePagination = await this.loadThemesList({
				query: { per_page: 1, page: 1 },
			});
			for (
				let i = 1;
				i <= Math.ceil(configPagination.total_records / 2500);
				i++
			) {
				await this.loadGroupConfigs({
					query: { per_page: 2500, page: i },
				});
				this.configListLocal.push(...this.getListOfGroupConfigs);
			}
			for (
				let i = 1;
				i <= Math.ceil(themePagination.total_records / 2500);
				i++
			) {
				await this.loadThemesList({
					query: { per_page: 2500, page: i },
				});
				this.themesListLocal.push(...this.getThemesList);
			}
			this.configLoading = false;
			this.themesLoading = false;
		},

		onAddClick() {
			this.addDialog = true;
		},
		loadList() {
			// const casino_id = { of: [this.casinoFilter] };
			const casino_id = this.casinoFilter
				? {
						of: [this.casinoFilter],
				  }
				: undefined;
			const deleted = !this._usrFlagsSome({
				key: "bonus.groups.list",
				val: this.permissions.DELETE,
			})
				? { eq: null }
				: undefined;
			return this.loadGroups({ payload: { deleted, casino_id } });
		},
	},
};
</script>

<style scoped>
.hoverTheme:hover {
	background-color: var(--v-primary-base);
	color: var(--v-kajot-text-base) !important;
}
</style>
